
ul.social-links {
  padding: 0;
  margin: 2em 0;
  text-align: center;

  img {
    width: 64px;
    height: 64px;
  }

  li {
    display: inline;
    list-style-type: none;
  }
}
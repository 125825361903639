
.btn-floating {
    width: 47px;
    height: 47px;
    position: relative;
    z-index: 1;
    vertical-align: middle;
    display: inline-block;
    overflow: hidden;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    margin: 10px;
    border-radius: 50%;
    padding: 0;
    cursor: pointer;
  
    box-shadow: 0 5px 11px 0 rgba(0,0,0,.18), 0 4px 15px 0 rgba(0,0,0,.15);
  
    i {
      display: inline-block;
      width: inherit;
      text-align: center;
      color: #fff;
  
      font-size: 1.25rem;
      line-height: 47px;
    }
  }
  
  .card {
    flex: 1;
  
    .btn-action {
      margin-top: -23px;
      margin-bottom: -23px;
    }
  }
  
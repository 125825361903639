@charset "utf-8";

body {
  background-color: #d0d0d0;

  .container.primary {
    background-color: white;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
  }
}

.books {
  .card .view {
    height: 300px;
    background-size: cover;
  }
}

.talks {
  .card .view {
    height: 150px;
    background-size: cover;
  }
}

.blog-index {
  .card {
    .view {
      background-size: cover;
      height: 150px;
    }
  }
}

.nav-link {
  text-transform: uppercase;
}

.blog-landing {
  background-size: cover;
  height: 300px;

  .container.attribution {
    text-align: right;
    margin-top: 220px;

    a {
      color: white;
    }
  }

  .navbar {
    background-color: rgba(255,255,255,0.5) !important;
    a {
      color: black !important;
    }

    a.nav-link {
      color: black !important;
    }

    li.active {
      font-weight: bold;
      border-bottom: 2px solid black;
    }
  }
}

.primary {
  pre {
    border: 0;
    border-radius: .25rem;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
    padding: 1em;

    .lineno {
      color: #ddd;
    }
  }

  p img {
    max-width: 100%;
  }

  .post-meta {
    text-align: center;
    font-style: italic;
    color: #666;

    padding-top: 1em;
    padding-bottom: 1em;
  }

  article.post, .card {
    h1, h2, h3, h4 {
      font-family: 'Slabo 27px', serif;
      text-align: center;
    }

    h1:first-of-type {
      padding-top: 1em;
      padding-bottom: 1em;
    }
    
    h2, h3 {
      margin-top: 2em;
      margin-bottom: 1em;
    }
  }

  article.post {
    @media (min-width: 768px) {
      padding-left: 3em;
      padding-right: 3em;
    }
    padding-bottom: 3em;
  }
}

footer {
  h5 {
    text-transform: uppercase;
  }

  ul {
    padding-left: 0;
  }
}

.row.equal-height {
  display: flex;
  flex-wrap: wrap;
}
.row.equal-height > [class*='col-'] {
  display: flex;
  flex-direction: column;
}

.my-alert {
  border-radius: .25rem;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 16px;

  .icon {
    font-size: 32px;
    margin: 1em 8px 0 8px;

    @media (min-width: 768px) {
      font-size: 96px;
      margin: 0px 16px;
    }

    float: left;
    position: relative;
  }

  .my-alert-body {
    margin-top: 2em;
    margin-bottom: 2em;
    padding-left: 48px;

    @media (min-width: 768px) {
      padding-left: 128px;
    }

    a {
      color: white;
      text-decoration: underline;
    }
  }
}

// Import partials from `sass_dir` (defaults to `_sass`)
@import "syntax-highlighting";
@import "action-button";
@import "social-links";